import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’re still hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
`}<a parentName="p" {...{
        "href": "https://github.com/mozilla/tts"
      }}>{`text-to-speech`}</a>{` (TTS) and `}<a parentName="p" {...{
        "href": "https://github.com/mozilla/deepspeech"
      }}>{`speech-to-text`}</a>{`
(STT) engines (over `}<a parentName="p" {...{
        "href": "https://somsubhra.github.io/github-release-stats/?username=mozilla&repository=deepspeech&page=1&per_page=300"
      }}>{`650K downloads`}</a>{`
and 23K GitHub stars), with the backing of top-flight investors `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-tts-deep-learning-engineer"
        }}>{`Senior TTS Deep Learning Engineers`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "hello-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hello-",
        "aria-label": "hello  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Hello! 👋🐸`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`We’ve got quite a month for you🚀!`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/releases/v1.0.0"
      }}>{`1.0 release`}</a>{` of Coqui 🐸STT is here🎊!
It’s been brewing for quite some time, but we feel it’s finally ready! 1.0 brings with it
a ton of features:`}</p>
    <ul>
      <li parentName="ul">{`Exclusive use of TensorFlow Lite`}</li>
      <li parentName="ul">{`Experimental iOS support`}</li>
      <li parentName="ul">{`Built-in support for transfer learning and fine tuning`}</li>
      <li parentName="ul">{`Support for using our training and evaluation tools from Jupyter Notebooks,`}</li>
    </ul>
    <p>{`and a lot more!`}</p>
    <p>{`Alongside the Coqui 🐸STT 1.0 release, we’re also releasing our best ever
`}<a parentName="p" {...{
        "href": "https://coqui.ai/english/coqui/v1.0.0-huge-vocab"
      }}>{`English STT model`}</a>{`!
It was trained on almost 47,000 hours of English and can outperform humans🤯! We’ve come a
long way!`}</p>
    <p>{`If that wasn’t enough, we’re also releasing a new version of our TTS engine
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.3.0"
      }}>{`v0.3.0, 🐸TTS`}</a>{`. The new version
introduces a New `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/models/forward_tts.html"
      }}>{`ForwardTTS API`}</a>{`,
An English `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2008.03802"
      }}>{`SpeedySpeech`}</a>{` model trained on LJSpeech
(the most compact TTS model we’ve released to the date), and A fine-tuned
`}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2106.07889"
      }}>{`UnivNet`}</a>{` vocoder, and a lot more TTS goodness.`}</p>
    <p>{`We’ve also doubled down on STT responsiveness. Latency matters a lot! For the Coqui 🐸STT 1.0
release we spent a ton of time optimizing the inference library that powers all 🐸STT packages,
and it shows. It’s lean, streamable, and loads from disk in the blink of an eye.`}</p>
    <p>{`Also, as always, over the last month we’ve been spreading the “word of Coqui”. We spoken at the
`}<a parentName="p" {...{
        "href": "https://voicelunch.com/"
      }}>{`Voice Lunch`}</a>{`, to the students of `}<a parentName="p" {...{
        "href": "https://www.rug.nl/masters/voice-technology/"
      }}>{`University of Groningen’s new
MSc in Voice Technology`}</a>{`, and at Hyde Park’s
Speakers’ Corner soapbox. (Well, not really the Hyde Park bit.)`}</p>
    <p>{`Anyway, enjoy the newsletter!`}</p>
    <h3 {...{
      "id": "coqui-stt-10-released",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-stt-10-released",
        "aria-label": "coqui stt 10 released permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Coqui 🐸STT 1.0 Released`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`Today Coqui is proud to release `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/releases/v1.0.0"
      }}>{`Coqui STT 1.0`}</a>{`,
the first stable release of our open source speech-to-text engine. Coqui STT offers high quality,
offline speech-to-text technology that’s simple to integrate into your applications, thanks to
our easy to use API which supports multiple architectures, platforms, and programming languages.`}</p>
    <p>{`We have continuously worked to make our speech-to-text engine faster, more capable and easier to
use. Developers are already relying on our technology to build voice powered applications around
the world (and maybe even on the moon!). Today, we are proud to announce our first stable release.
When you integrate Coqui STT 1.0 into your application, you’ll be able to easily upgrade to newer
1.x versions and take advantage of performance improvements and bug fixes without having to change
your code.`}</p>
    <p>{`Coqui STT 1.0 is available for download from our `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/releases/v1.0.0"
      }}>{`release page`}</a>{`.
Since our last release, 0.9.3, we’ve continued to work on performance, stability, flexibility and
accuracy. We’ve`}</p>
    <ul>
      <li parentName="ul">{`Moved exclusively to TensorFlow Lite to make sure our most important runtime variant receives full attention and care,`}</li>
      <li parentName="ul">{`Released experimental iOS support,`}</li>
      <li parentName="ul">{`Landed built-in support for transfer learning and fine tuning,`}</li>
      <li parentName="ul">{`Added support for using our training and evaluation tools from Jupyter Notebooks,`}</li>
      <li parentName="ul">{`Streamlined alphabet generation and dataset splitting,`}</li>
    </ul>
    <p>{`and more!`}</p>
    <p>{`Try Coqui STT today by following our `}<a parentName="p" {...{
        "href": "https://stt.readthedocs.io/en/latest/"
      }}>{`usage guide`}</a>{` for your
favorite programming language. Our `}<a parentName="p" {...{
        "href": "https://coqui.ai/english/coqui/v1.0.0-huge-vocab"
      }}>{`1.0 English model`}</a>{` has
been trained on over 47 thousands of hours of speech data, including the latest release of the
Common Voice dataset with 2000 hours of speech. It is available for download in the
`}<a parentName="p" {...{
        "href": "https://coqui.ai/english/coqui/v1.0.0"
      }}>{`Coqui Model Zoo`}</a>{`, where we also document all of the training
parameters that were used to create it. You can also find our latest release in your favorite package
manager: we offer packages for Python, Node.JS, Electron, Java on Android, and C/C++.`}</p>
    <h3 {...{
      "id": "-best-ever-stt-english-model-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-best-ever-stt-english-model-",
        "aria-label": " best ever stt english model  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🔥 Best EVER 🐸STT English model 🔥`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUEBgj/xAAVAQEBAAAAAAAAAAAAAAAAAAADBP/aAAwDAQACEAMQAAABzU9T2ML0xBED/8QAGxAAAwACAwAAAAAAAAAAAAAAAgMEAAEFBhH/2gAIAQEAAQUC0O9ZJxdVwOkNDDP0I+wVQyuoNzf/xAAXEQEAAwAAAAAAAAAAAAAAAAACEBEx/9oACAEDAQE/AUKyP//EABYRAQEBAAAAAAAAAAAAAAAAAAIQUf/aAAgBAgEBPwErZ//EAB8QAAEDAwUAAAAAAAAAAAAAAAIAAQMREkEQEyExUf/aAAgBAQAGPwJXRREY+4TgbUJtNiJxYLrusopDKpFy7r//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/ITr0RQDYSbeXO77FxqIkW+FYfA6x1n//2gAMAwEAAgADAAAAEJMf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxA1TvNiAz//xAAXEQEBAQEAAAAAAAAAAAAAAAABACFx/9oACAECAQE/EFdzyFv/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMVFBcZH/2gAIAQEAAT8QtpLz3jwL1IfBd8YbOXTZQeT3l6kADGgId0ABr1NY3rbm0auf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/55dce4b9588d7066049d2557093dabd2/dbdff/best-stt-model.jpg",
          "srcSet": ["/static/55dce4b9588d7066049d2557093dabd2/0988f/best-stt-model.jpg 250w", "/static/55dce4b9588d7066049d2557093dabd2/d1f95/best-stt-model.jpg 500w", "/static/55dce4b9588d7066049d2557093dabd2/dbdff/best-stt-model.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`This is a very exciting day for us, because we are releasing our best ever Speech-to-Text model for
English!`}</p>
    <p>{`We trained this model on almost 47,000 hours of English 🤯 … And it shows`}</p>
    <h4 {...{
      "id": "-accurate",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-accurate",
        "aria-label": " accurate permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👉 Accurate`}</h4>
    <p>{`✔️ Huge improvement in accuracy over all past versions`}</p>
    <p>{`With this new model, we get 4.5% word error rate on Librispeech clean (better than humans!). Our
previous v0.9.3 model has a 7.1% WER, so you should upgrade as soon as possible to feel the
difference:)`}</p>
    <p>{`More diverse voices in the training data means better demographic representation, and better
representation means better Speech-to-Text for everyone!`}</p>
    <h4 {...{
      "id": "-fast--local--small",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-fast--local--small",
        "aria-label": " fast  local  small permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👉 Fast && Local && Small`}</h4>
    <p>{`✔️ It runs on your computer`}</p>
    <p>{`✔️ It runs on your phone`}</p>
    <p>{`✔️ It runs on your watch`}</p>
    <p>{`We’ve doubled down on making models go everywhere you go. For the first time, we’re releasing two
versions of the model ready for Tensorflow Lite, in two sizes: small and extra-small:)`}</p>
    <p>{`Don’t let the sizes trick you, both these models are ready for the big leagues. The extra-small
model has optimally quantized weights, bringing this (open vocabulary!) speech recognizer down
to just 46 Megabytes. Oftentimes your application will benefit from a language model, which
comes with a size↔️accuracy tradeoff. We are releasing a few language models so you can try out
for yourself a language model under a kilobyte versus one in the order of Gigs. Depending on your
application, you can choose the right model for you… or you can build your own!`}</p>
    <h4 {...{
      "id": "-easy-to-customize",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-easy-to-customize",
        "aria-label": " easy to customize permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👉 Easy to Customize`}</h4>
    <p>{`STT is built so that you can easily improve performance for your application, no matter how
specific or how rare the vocabulary.`}</p>
    <p>{`If you’ve got doctors talking about doctor things, no problem! Lawyers talking about lawyer
things? No sweat 😎 No human knows all the jargon in all domains, but anyone can learn…
And the same goes for Coqui STT!`}</p>
    <p>{`This kind of customization happens at the language model, and you can easily (and quickly!)
create custom language models for your custom applications.`}</p>
    <h4 {...{
      "id": "-download-it-yourself",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#-download-it-yourself",
        "aria-label": " download it yourself permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👉 Download it yourself`}</h4>
    <p>{`✔️ Newest member of Model Zoo`}</p>
    <p>{`✔️ Apache 2.0 licensed`}</p>
    <p>{`We love open source, and we’re releasing this new v1.0.0 model under the Apache 2.0 license 🎉`}</p>
    <p>{`Download this new model from the Coqui model zoo, and check out all the other community models!`}</p>
    <h3 {...{
      "id": "v030-tts-delivered-fresh",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#v030-tts-delivered-fresh",
        "aria-label": "v030 tts delivered fresh permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`v0.3.0, 🐸TTS Delivered Fresh`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This new version introduces a new `}<a parentName="p" {...{
        "href": "https://tts.readthedocs.io/en/latest/models/forward_tts.html"
      }}>{`ForwardTTS API`}</a>{`
and 2 pre-trained models, an English `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2008.03802"
      }}>{`SpeedySpeech`}</a>{` model
trained on LJSpeech and a fine-tuned `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2106.07889"
      }}>{`UnivNet`}</a>{` vocoder.`}</p>
    <p>{`Forward TTS API provides a unified interface for all the current and future feed-forward TTS
models. We currently support `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2008.03802"
      }}>{`SpeedySpeech`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2006.06873"
      }}>{`FastPitch`}</a>{`, `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/1905.09263"
      }}>{`FastSpeech`}</a>{`
architectures through this API. You can also create your own custom architectures by defining
your own Encoder, Decoder networks without any other fine-grained changes.`}</p>
    <p>{`We are also releasing the following pre-trained models:`}</p>
    <ul>
      <li parentName="ul">{`English `}<a parentName="li" {...{
          "href": "https://arxiv.org/abs/2008.03802"
        }}>{`SpeedySpeech`}</a>{` model trained on the LJSpeech dataset.
This is the most compact TTS model we’ve released to the date.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`tts --text "This is a sample text for my model to speak." --model_name tts_models/en/ljspeech/speedy-speech
`}</code></pre>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2106.07889"
        }}>{`UnivNet`}</a>{` vocoder fine-tuned for the above model.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`tts --text "This is how it is." --model_name tts_models/en/ljspeech/tacotron2-DDC_ph
`}</code></pre>
    <p>{`Check the `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.3.0"
      }}>{`release notes`}</a>{` for all the details
of the release and `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS/issues/378"
      }}>{`dev plans`}</a>{` to see what is next.`}</p>
    <h3 {...{
      "id": "tts-papers-picks-of-the-month",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tts-papers-picks-of-the-month",
        "aria-label": "tts papers picks of the month permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`📎TTS papers picks of the month`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG8fTjqGJiV/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxEhIv/aAAgBAQABBQKjsYYnqRTLzWzT/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAABABETH/2gAIAQEABj8CKULD/8QAHRAAAgIBBQAAAAAAAAAAAAAAAREAITEQUXGhsf/aAAgBAQABPyHIa9jhO4KABXMVUMCFR30P/9oADAMBAAIAAwAAABCMz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQWFRgfD/2gAIAQEAAT8QE79Ycp9ayZ+Irb85V2jkm+5fmpmEui4RejKd/pkoKGl5c//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg",
          "srcSet": ["/static/ee7ae83975f5a8e36a44fa9649c1ad37/0988f/monthly-papers.jpg 250w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/d1f95/monthly-papers.jpg 500w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <h4 {...{
      "id": "towards-universal-text-to-speech-pdf",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#towards-universal-text-to-speech-pdf",
        "aria-label": "towards universal text to speech pdf permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Towards Universal Text-to-Speech (`}<a parentName="h4" {...{
        "href": "http://www.interspeech2020.org/uploadfile/pdf/Wed-3-4-3.pdf"
      }}>{`PDF`}</a>{`)`}</h4>
    <p>{`This paper presents a multilingual TTS framework that can be trained on 1250 hours of professional recordings
from multiple languages and then fine-tuned with new speakers and languages with only 5-6 minutes of recordings.`}</p>
    <p>{`The model proposed in the paper comprises an encoder-decoder Transformer model with a speaker and language
conditioning networks and a WaveNet vocoder. The conditioning networks take one-hot vectors representing
the speaker or language ID and project to a hidden conditioning representation that is concatenated to
the encoder output.`}</p>
    <p>{`This show that this model can learn low-resource languages that cannot be learned by single speaker models
due to insufficient data. In their experiments, a single speaker model needs at least 3 hours of recordings
whereas the proposed model is able to learn with only 6 minutes.`}</p>
    <h4 {...{
      "id": "adaspeech-adaptive-text-to-speech-for-custom-voice-pdf",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#adaspeech-adaptive-text-to-speech-for-custom-voice-pdf",
        "aria-label": "adaspeech adaptive text to speech for custom voice pdf permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`AdaSpeech: Adaptive Text to Speech for Custom Voice (`}<a parentName="h4" {...{
        "href": "https://openreview.net/pdf?id=Drynvt7gg4L"
      }}>{`PDF`}</a>{`)`}</h4>
    <p>{`This paper presents a way to learn new speakers with a single backbone architecture while keeping the number
of new parameters introduced to the system small.`}</p>
    <p>{`The overall architecture is based on the FastSpeech2 model with Pitch and Variance predictors. They also
introduce 3 more conditioning networks for better understanding the acoustic properties of a speaker.`}</p>
    <p>{`All the networks use Layer Normalisation at different stages. They proposed Conditional Layer Normalisation
as a means to reduce the complexity required for fine-tuning with each new speaker.`}</p>
    <p>{`They propose to train separate Speaker Conditioning modules to predict the scale and bias values for each
Layer Normalisation layer. This way, they are able to store only these Speaker Conditioning modules for
each speaker and keep the rest of the model the same as they add more speakers to the system`}</p>
    <h3 {...{
      "id": "-building-coqui-stt-for-responsiveness",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-building-coqui-stt-for-responsiveness",
        "aria-label": " building coqui stt for responsiveness permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🏃🏿 Building Coqui STT for responsiveness`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABQAG/8QAFwEAAwEAAAAAAAAAAAAAAAAAAQIEBf/aAAwDAQACEAMQAAABMJzziY5Njo2//8QAGxAAAgMAAwAAAAAAAAAAAAAAAwQCBQYBByX/2gAIAQEAAQUC6/qRWZec/wCkXOGaNlbNgTtZObgnyRgz/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEhAjH/2gAIAQMBAT8B32CUP//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPwFJdHf/xAAjEAACAQMEAQUAAAAAAAAAAAABAgMAERIEISIxFDJBUWKx/9oACAEBAAY/AtRJqI0OmVSCCw4/a1L4jCSI7qrG2R9xUrI0YAa3OZQf2hDEyoswxe63yHxTrHI0GJJv6t8rUy4ddm/df//EABsQAQACAwEBAAAAAAAAAAAAAAERIQAxQWHw/9oACAEBAAE/Ib/j1VOlx7jhSNBYVb9eJ0gwEnjh8h42jtjvAmWyBO1L3B8pUdDrqs//2gAMAwEAAgADAAAAECA//8QAGREBAAMBAQAAAAAAAAAAAAAAAQARIbFh/9oACAEDAQE/EA1TW76JnOxwFz//xAAZEQADAAMAAAAAAAAAAAAAAAAAAREhoeH/2gAIAQIBAT8QZQUcPXT/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMVFBgf/aAAgBAQABPxAMTJpYdlAJHFmH2ghTIjbyD2JrDpTfiKNWqH5h14UEaQYxnxMloq0AKAAGirQpMd0YrlWBwfKz1z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/2d77532e872dd695eb55f2495fa33512/dbdff/stt-responsiveness.jpg",
          "srcSet": ["/static/2d77532e872dd695eb55f2495fa33512/0988f/stt-responsiveness.jpg 250w", "/static/2d77532e872dd695eb55f2495fa33512/d1f95/stt-responsiveness.jpg 500w", "/static/2d77532e872dd695eb55f2495fa33512/dbdff/stt-responsiveness.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/reuben"
      }}>{`Reuben Morais`}</a></p>
    <p>{`One of the most exciting aspects of working on speech technology is the promise of a future where interactions
with devices can be as fluid and effortless as telling someone what you’re trying to achieve. To be understood
by devices rather than needing to learn many languages.`}</p>
    <p>{`In everyday usage, we usually start off with a very clear task in mind: check the shipping status of the thing
you ordered last week, try to find a good day and time to fit a coffee and catch-up with a friend, make sure
you’re not forgetting any of the documents you’re supposed to bring for the visa application. But before you
can accomplish those tasks, you have to convert them into sequences of steps in a language that your phone
can understand.`}</p>
    <p>{`We’re all essentially programming our devices, and not in a fun, playful way: we still have to work to get
them to do very simple tasks. As the technical capabilities of consumer devices grow more and more, so does
the complexity of the languages we use to control them. As these devices become more ubiquitous and more
interactive, from wearables to virtual reality to brain-computer interfaces, we need better ways to interact
with and control our devices, if we want to explore all their potential.`}</p>
    <p>{`At Coqui we believe conversational interfaces are the key to solving many of these user experience problems
and multiplying the potential of what devices can do for all people, even those who aren’t great fans of
programming. Speech technology has made great strides in the past few years: we’ve built systems that learn
languages by themselves, and that often perform better than humans. But there’s also still a lot to do:
speech AI needs to speak many more languages, it needs to be more intelligent and adaptable, and it needs to
be more responsive.`}</p>
    <p>{`We’re hard at work making this a reality: a key focus of our speech-to-text engine Coqui STT has been
responsiveness, from the conception of the project. Latency in user interactions matters. A lot. Ask a user
experience designer. Ask someone who works on optimizing conversions. Or just remember what it feels like to
have a weird conversation filled with awkward silences. This is why we spent a ton of time optimizing the
inference library that powers all 🐸STT packages: keeping the core library lean, making it end-to-end
streamable, using efficient data structures that can be loaded quickly from disk, and testing it extensively.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "50%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABcRAAAXEQHKJvM/AAACBElEQVQoz32T63KbMBCFef/36Rv0Fk8y6aSp41twMBiDDQiExE3A1yXpTH+1Z0ajRVqdvZzFs8GROvCp6pqqLNFaY62FeaapNbqqqK5HVHahUgqtCt7hNPR/bMHc3nAmxnt7XvH69J0wOhMGAVEYvhMvGPoe50aG6sTYlrjB4frug6FLoTn/JdQ+fb7BS8uE8y3k185nE4SsdweO54SittyfFKvI8Hn9yuaScxfWfDl+nCWSsS5CcuG3C2N/Zshe8LqmlSwctlA02x2tlFv7PiY4oSWAOV04hy9UWUrph5RBhAoS8u2KPNvRXQv6IEavPpElj3j9UtY4UhVyIauutPQspZWyizjGZhnB6SDniuTtSFcqqlTI85j4Folfhb1lxMcNyuZ4szT/X+i6lqLIMSKUkmCFCGJMzf/g6abDdo7M9OSNo7DLPlC2jqsZKNqJa91T95PYI5n45HagcRO9CNaNE05y6oaBcRrxbrolqTru9lfufp54ChU/9gnrt4zHQ8rDNubrPmMTKe63Cd/WZx72KZerjJMELZShqQxOSh+nWUTpOqZ5YpDS5tcDY2MZwjf6S0QTBYyytLoxGy1i7ZjTC81yH4c0TUOfyLd/oHz+KYQT3iiCTGIYUXuQHljZrQjVivJGelgaiy9ipNcbR5nRpbxKJqGXN8sP0Ilf44b3Nwt+A6Qn+HYezBrAAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/6b9f563c961d7e0a23c19ddf5d944531/da8b6/stt-latancy.png",
          "srcSet": ["/static/6b9f563c961d7e0a23c19ddf5d944531/43fa5/stt-latancy.png 250w", "/static/6b9f563c961d7e0a23c19ddf5d944531/c6e3d/stt-latancy.png 500w", "/static/6b9f563c961d7e0a23c19ddf5d944531/da8b6/stt-latancy.png 1000w", "/static/6b9f563c961d7e0a23c19ddf5d944531/2e9ed/stt-latancy.png 1500w", "/static/6b9f563c961d7e0a23c19ddf5d944531/9fabd/stt-latancy.png 2000w", "/static/6b9f563c961d7e0a23c19ddf5d944531/6f79e/stt-latancy.png 2100w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`We’ve previously talked about some of this work, such as the changes needed to make the entire inference
process streamable. The diagram above shows the latency gains from this change alone. You can read more
about it in `}<a parentName="p" {...{
        "href": "https://coqui.ai/blog/stt/deepspeech-0-6-speech-to-text-engine"
      }}>{`our blog post`}</a>{`.`}</p>
    <p>{`We think responsiveness is crucial for building engaging, enjoyable user experiences with speech. We will
continue to push the boundaries of performance for speech AI, creating more accurate and more efficient
models, moving processing as close as possible to end users. We’ll make it as easy as calling a SaaS
endpoint on a datacenter half a continent away, but with snappy responses for engaged conversations without
awkward silences.`}</p>
    <p>{`If this sounds like something you’d like to work on, `}<a parentName="p" {...{
        "href": "https://coqui.ai/jobs"
      }}>{`we’re hiring`}</a>{`! Coqui is looking
for talented engineers with experience in speech AI, MLOps and full stack web development. Come help us
make this future a reality!`}</p>
    <h3 {...{
      "id": "-voice-lunch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-voice-lunch",
        "aria-label": " voice lunch permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🍱 Voice Lunch`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBQYH/8QAFQEBAQAAAAAAAAAAAAAAAAAAAwT/2gAMAwEAAhADEAAAAe1QtFEulFFAf//EABoQAAMBAAMAAAAAAAAAAAAAAAMEBQACEhP/2gAIAQEAAQUCqvlQWn1SEJvLiyNOKsmXtv/EABgRAQEAAwAAAAAAAAAAAAAAAAIBEBEx/9oACAEDAQE/AShBq9x//8QAGBEBAQADAAAAAAAAAAAAAAAAAQIDECH/2gAIAQIBAT8BuMjYjzX/xAAgEAACAgEDBQAAAAAAAAAAAAABAgMREgAQMRMhMmGR/9oACAEBAAY/AlMCZyuaHy9RM0yywyt06ruG9bYuLHOlkUFnUsy5HxJ52//EABwQAQACAwADAAAAAAAAAAAAAAEAESExUUFxgf/aAAgBAQABPyHEaXSwyV/CEH4CRYpY2Yd37gDE3OKdHpGj4F3kg7WL5Lk//9oADAMBAAIAAwAAABA7/wD/xAAZEQADAAMAAAAAAAAAAAAAAAAAAUERIcH/2gAIAQMBAT8QeEVOzZk//8QAGhEAAQUBAAAAAAAAAAAAAAAAQQABESFh8P/aAAgBAgEBPxATIdujLcVDr//EABsQAQEAAwADAAAAAAAAAAAAAAERACExQXGR/9oACAEBAAE/EAucHiUFLt1T2Zo6rDogOgQRLw4DTjkcYEGD4nhK/cjpuf0JwC6KiQguCUl3n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/26e280c36695e2d0c351ef812239d70f/dbdff/voice-lunch.jpg",
          "srcSet": ["/static/26e280c36695e2d0c351ef812239d70f/0988f/voice-lunch.jpg 250w", "/static/26e280c36695e2d0c351ef812239d70f/d1f95/voice-lunch.jpg 500w", "/static/26e280c36695e2d0c351ef812239d70f/dbdff/voice-lunch.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Coqui made its first appearance on `}<a parentName="p" {...{
        "href": "https://voicelunch.com/"
      }}>{`Voice Lunch`}</a>{`. Our co-founder, Josh Meyer, walked a
virtual room full of linguists (many of whom don’t usually write code) through one of our beginner-friendly
Python notebooks. Each participant was able to train a speech-to-text model for the Serbian language, on their
very own!`}</p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/train_with_common_voice.ipynb"
      }}>{`notebook`}</a>{`
for yourself and train your own model… you don’t have to know how to code, and it’s fun!`}</p>
    <h3 {...{
      "id": "-university-of-groningen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-university-of-groningen",
        "aria-label": " university of groningen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🎓 University of Groningen`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "18.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAEABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAT/2gAMAwEAAhADEAAAAYuCs1QZ3//EABkQAQACAwAAAAAAAAAAAAAAAAMCBQABEf/aAAgBAQABBQKVYSFXnqKR4ef/xAAYEQACAwAAAAAAAAAAAAAAAAAAAgEiMf/aAAgBAwEBPwFJsLh//8QAFhEAAwAAAAAAAAAAAAAAAAAAAhAy/9oACAECAQE/ATlf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIAAxExQoH/2gAIAQEABj8CpbXBXjGlFPUQqFHs/8QAGBABAAMBAAAAAAAAAAAAAAAAAQARITH/2gAIAQEAAT8hYFd4utwMaVW9cgNAC7pn/9oADAMBAAIAAwAAABD8P//EABgRAAMBAQAAAAAAAAAAAAAAAAABESHw/9oACAEDAQE/EGVvYM4P/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAgEBPxCEHp//xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhQaH/2gAIAQEAAT8Qp8tTGsSBusEhqmpDx55MkWwpvW5//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/586b2ac858f0026a7e8a17be070cd150/dbdff/groningen.jpg",
          "srcSet": ["/static/586b2ac858f0026a7e8a17be070cd150/0988f/groningen.jpg 250w", "/static/586b2ac858f0026a7e8a17be070cd150/d1f95/groningen.jpg 500w", "/static/586b2ac858f0026a7e8a17be070cd150/dbdff/groningen.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Coqui has already made its way into the curriculum of the next generation of Speech Tech developers. Josh Meyer
gave `}<a parentName="p" {...{
        "href": "https://docs.google.com/presentation/d/1nz0PAFfApny3rfZM3v_BUOIlJRy4XR1Jys3TOWNllPY/edit?usp=sharing"
      }}>{`an invited lecture`}</a>{`
to the inaugural class of Masters students at the `}<a parentName="p" {...{
        "href": "https://www.rug.nl/masters/voice-technology/"
      }}>{`University of Groningen’s new MSc in Voice Technology`}</a>{`.
These students will be building the future of voice, and they’re starting their journey with Coqui in their
toolbox.`}</p>
    <p>{`The presentation covered both STT and TTS, with a walk-through of one of our
`}<a parentName="p" {...{
        "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/train_with_common_voice.ipynb"
      }}>{`Python notebooks`}</a>{`.
The University of Groningen is located in a Frisian-speaking region of the Netherlands, and there’s a keen
interest in traditionally under-served languages among students and faculty. Coqui’s fine-tuning capabilities
and multilingual lean make it a great fit for this next generation of language technologists.`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      